// Product icons
.background-gallery-empty {
  background-image: url(../../../images/background/gallery_back.png);
  background-size: cover;
  width: 1920px;
  height: 1032px;
  width: 100%;
}
.background-gallery-banner {
  background-image: url(../../../images/background/gallery_banner.svg);
  background-size: cover;
  width: 1300px;
  height: 200px;
  width: 100%;
}
