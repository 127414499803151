// Product icons
.icon-product-microsoft365-16 {
  background-image: url(../../../images/productIcons/microsoft_365.svg);
  background-size: cover;
  width: 16px;
  height: 16px;
}

.icon-product-yammer-16 {
  background-image: url(../../../images/productIcons/yammer.svg);
  background-size: cover;
  width: 16px;
  height: 16px;
}

.icon-product-azure-16 {
  background-image: url(../../../images/productIcons/azure.svg);
  background-size: cover;
  width: 16px;
  height: 16px;
}

.icon-product-dynamics-365-16 {
  background-image: url(../../../images/productIcons/dynamics_365.svg);
  background-size: cover;
  width: 16px;
  height: 16px;
}

.icon-product-sql-16 {
  background-image: url(../../../images/productIcons/sql.svg);
  background-size: cover;
  width: 16px;
  height: 16px;
}

.icon-product-power-bi-16 {
  background-image: url(../../../images/productIcons/power_bi.svg);
  background-size: cover;
  width: 16px;
  height: 16px;
}

.icon-product-office-16 {
  background-image: url(../../../images/productIcons/office.svg);
  background-size: cover;
  width: 16px;
  height: 16px;
}

.icon-product-outlook-16 {
  background-image: url(../../../images/productIcons/outlook.svg);
  background-size: cover;
  width: 16px;
  height: 16px;
}

.icon-product-project-16 {
  background-image: url(../../../images/productIcons/project.svg);
  background-size: cover;
  width: 16px;
  height: 16px;
}

.icon-product-skype_for_business-16 {
  background-image: url(../../../images/productIcons/skype_for_business.svg);
  background-size: cover;
  width: 16px;
  height: 16px;
}

.icon-product-teams-16 {
  background-image: url(../../../images/productIcons/teams.svg);
  background-size: cover;
  width: 16px;
  height: 16px;
}

.icon-product-visio-16 {
  background-image: url(../../../images/productIcons/visio.svg);
  background-size: cover;
  width: 16px;
  height: 16px;
}

.icon-product-share_point-16 {
  background-image: url(../../../images/productIcons/share_point.svg);
  background-size: cover;
  width: 16px;
  height: 16px;
}

.icon-product-windows-16 {
  background-image: url(../../../images/productIcons/windows.svg);
  background-size: cover;
  width: 16px;
  height: 16px;
}

// General icons
.icon-partner-asp {
  background-image: url(../../../images/generalIcons/advspec.svg);
  background-size: cover;
  width: 18px;
  height: 18px;
}

.icon-partner-azure-msp {
  background-image: url(../../../images/generalIcons/azure.svg);
  background-size: cover;
  width: 18px;
  height: 14px;
}

.icon-partner-competency {
  background-image: url(../../../images/generalIcons/competency.svg);
  background-size: cover;
  width: 18px;
  height: 18px;
}

.icon-partner-expertise {
  background-image: url(../../../images/generalIcons/expertise.svg);
  background-size: cover;
  width: 17px;
  height: 16px;
}

.icon-sent {
  background-image: url(../../../images/generalIcons/sent.svg);
  background-size: cover;
  width: 17px;
  height: 16px;
}

.icon-empty-gallery {
  background-image: url(../../../images/generalIcons/emptygallery.svg);
  background-size: cover;
  width: 200px;
  height: 200px;
}

.icon-sort {
  background-image: url(../../../images/generalIcons/sort.svg);
  background-size: cover;
  width: 16px;
  height: 16px;
}

.icon-info {
  background-image: url(../../../images/generalIcons/info.svg);
  background-size: cover;
  width: 16px;
  height: 16px;
}

.icon-location {
  background-image: url(../../../images/generalIcons/location.svg);
  background-size: cover;
  width: 11px;
  height: 16px;
}

.icon-diversity {
  background-image: url(../../../images/generalIcons/diversity.svg);
  background-size: cover;
  width: 25px;
  height: 28px;
}
