.select-location-dialog-container {
  flex-basis: 560px;
}

.select-location-dialog {
  width: 500px;
  direction: ltr;
  text-align: left;
  padding-top: 8px;
  padding-right: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
}

.select-location-dialog-rtl {
  width: 500px;
  direction: rtl;
  text-align: right;
  padding-top: 8px;
  padding-right: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
}

.select-location-dialog-title-box {
  display: flex;
  margin-top: 16px;
  flex-direction: row;
}

.select-location-dialog-title {
  font-weight: 600;
  font-size: 20px;
  width: 100%;
  text-align: start;
}

.select-location-dialog-cancel-icon {
  margin-left: 24px;
  color: #323130;
  position: absolute;
  right: 24px;
}

.select-location-dialog-cancel-icon-rtl {
  color: #323130;
  position: absolute;
  left: 24px;
}

.select-location-dialog-input-row {
  margin-top: 16px;
}

.select-location-dialog-apply-button-margins {
  margin-left: 8px;
  margin-right: 8px;
}

.select-location-dialog-buttons-box {
  display: flex;
  justify-content: flex-end;
  margin-top: 56px;
}

.select-location-dialog-my-location-label {
  margin-top: 24px;
  font-size: 14px;
  font-weight: 600;
}

.select-location-dialog-select-location-label {
  margin-top: 24px;
  font-size: 14px;
  font-weight: 600;
}

.select-location-dialog-location-button-margins {
  margin-top: 8px;
}
