$iconSize: 24px;
$whiteBackground: #ffffff;

.stickycard {
  background-color: $whiteBackground;
  box-shadow: 0px 0.3px 0.9px 1.5px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
  height: 64px;
  width: 100%;
  position: fixed;
  z-index: 4;
  display: flex;
  justify-content: center;
  @media (max-width: 1470px) {
    height: 128px;
  }
  @media (max-width: 1023px) {
    top: 0px; // When the header is two lines of 54px each
  }
}

.stickycard-container {
  max-width: 1920px;
  display: flex;
  flex-direction: row;
  height: 64px;
  width: 100%;
  position: fixed;
  align-items: center;
  padding: 0px 20px 0px 30px;
  justify-content: space-between;
  overflow: hidden;
  @media (max-width: 1470px) {
    height: 128px;
  }
  @media (max-width: 705px) {
    flex-direction: column;
  }
}

.stickycard-sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.stickycard-left {
  display: flex;
  @media (max-width: 1470px) {
    flex-direction: column;
  }
  @media (max-width: 705px) {
    max-height: 64px;
  }
}

.stickcard-publisher {
  display: flex;
  align-items: center;
  padding-right: 48px;
  @media (max-width: 1470px) {
    padding: 18px 0px 18px 0px;
  }

  .stickycard-icon {
    flex-shrink: 0;
    flex-basis: auto;
    width: $iconSize;
    height: $iconSize;
    align-self: center;

    .sticky-icon-img {
      max-width: $iconSize;
      max-height: $iconSize;
    }
  }

  .stickycard-title {
    display: flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-left: 8px;
    color: #333333;
  }
}

.stickycard-right {
  @media (max-width: 1470px) {
    padding-top: 60px;
  }
  @media (max-width: 705px) {
    padding-top: 0px;
  }
}

.stickycard-tabs {
  padding: 8px 0px 0px 0px;
  align-items: center;
  @media (max-width: 705px) {
    display: none;
  }
}
