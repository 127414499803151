.product-icon-link-container {
  display: flex;
  align-items: center;
}

.card-products-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: relative;

  .more-product-item {
    margin-right: 10px;
    font-size: 12px;
  }

  .more-product-item-label-wrapper {
    flex-shrink: 0;
  }

  .card-products-box-icons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    position: relative;

    & > div {
      flex-shrink: 0;
      &:nth-of-type(1) {
        flex-grow: 1;
        max-width: 100%;
        overflow: hidden;
        flex-basis: initial;
      }
    }

    .icon-link {
      flex-shrink: 1;
      max-width: 100%;
      .product-item {
        font-size: 12px;
        margin-right: 10px;
        width: 100%;
      }
      .icon {
        flex-shrink: 0;
      }
    }
  }

  .more-product-item-label {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #006fc2;
    cursor: default;
    &:hover {
      border-bottom-color: #006fc2;
      border-bottom-width: 1px;
      border-bottom-style: solid;
    }
    @media(max-width: 320px){
      margin: -10px
    }
  }
}

// Hover card styles
.more-products-card {
  max-width: 400px;
  padding: 16px;
  .more-product-item {
    margin-right: 10px;
    font-size: 12px;
  }
  .more-product-icon-link {
    display: inline-flex;
    margin-top: 6px;
    .icon {
      margin-right: 6px;
    }
  }
}
