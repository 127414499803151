.partners-gallery {
  text-align: center;
  font-weight: bold;
  margin-left: 30px;
  margin-right: 30px;
  height: 100%;

  .partners-gallery-content-box {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    text-align: left;
    min-height: 100%;
    @media (max-width: $ms-screen-min-xl) {
      flex-direction: column;
    }
  }

  .gallery-header-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .gallery-header-search-result-box {
    flex-grow: 1;
  }

  .gallery-header-main-title {
    margin-top: 30px;
    text-align: start;
    font-size: 20px;
    font-weight: 600;
  }

  .gallery-header-sub-title {
    margin-top: 8px;
    text-align: start;
    font-size: 14px;
    font-weight: 300;
  }

  .gallery-header-no-location-alert-box {
    margin-top: 20px;
    margin-right: 64px;
    text-align: left;
    background-color: #f3f2f1;
    padding: 8px;
    flex-grow: 1;
  }

  .gallery-header-no-location-alert-message {
    font-size: 14px;
    font-weight: 400;
  }

  .gallery-header-no-location-alert-action {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #0078d4;
  }
}

.partners-gallery-empty {
  height: 1000px;
}

.partners-gallery-search-box {
  margin-top: 10px;
}
