.tab-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  clear: both;
  .separator {
    border-bottom: 1px solid $ms-color-gray50;
    position: relative;
    top: -8px;
  }
  .tab-headers {
    display: flex;
    flex-direction: row;
  }

  .default-tab {
    float: left;
    outline: none;
    height: 44px;

    &:focus {
      & > label {
        @extend %focused-element;
        border-bottom: 2px solid $ms-color-communicationPrimary;
      }
    }

    > label {
      display: inline-block;
      margin: 0 10px -2px 0;
      padding: 12px 8px 12px 8px;
      text-align: center;
      color: $ms-color-gray160;
      cursor: pointer;
      font-size: 14px;
      line-height: 20px;
      &:hover {
        background-color: $ms-color-gray20;
      }
    }
    [type='radio'] {
      display: none;
    }
  }
  .tab-selected {
    height: 44px;

    > label {
      color: $ms-color-gray160;
      font-weight: 600;
      border-bottom: 2px solid $ms-color-communicationPrimary;
      &:hover {
        background-color: $ms-color-gray20;
      }
    }
  }
  > .tab-content {
    display: block;
    position: relative;
    top: 10px;
    clear: both;
    padding-left: 5px;
    max-width: 100%;
  }
}

.tab-container.gray {
  .default-tab {
    &:focus {
      & > label {
        border-bottom: 2px solid $ms-color-communicationPrimary;
      }
    }
    > label {
      &:hover {
        background-color: $ms-color-gray30;
      }
    }
  }
  .tab-selected {
    > label {
      &:hover {
        background-color: $ms-color-gray30;
      }
    }
  }
}

$activeLinkIndecatorWidth: 5px;
$linkWidth: 180px;

.home-filters-panel {
  margin: auto;
  padding: 0px 20px 0px 20px;
  max-width: 1366px;
  width: 100%;
  display: flex;

  .tab-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .filter-panel-item-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;

      .filter-item {
        display: flex;
        color: $ms-color-black;
        border-radius: 6px;
        background: $ms-color-white;
        border: 1px solid $ms-color-gray30;
        box-sizing: border-box;
        box-shadow: $ms-depth-shadow-8;
        margin: 10px 10px;
        justify-content: center;

        .active-indecator {
          width: $activeLinkIndecatorWidth;
          height: 100%;
          background-color: $ms-color-communicationPrimary;
          border-radius: 6px 0 0 6px;
        }

        a {
          height: 55px;
          width: $linkWidth;
          display: flex;

          > * {
            align-self: center;
          }

          .filter-text {
            font-size: 14px;
            line-height: 20px;
            color: $ms-color-gray130;
            margin: 0px 8px 0px 10px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
          }
          .noFocus:focus {
            outline: none;
          }
          .productIcon {
            margin: 10px 0px 10px 10px;
          }
        }
        a {
          &:hover,
          &:focus {
            background-color: $ms-color-communicationTint40;
            border-radius: 6px;
          }
        }
        &.active {
          background-color: $ms-color-communicationTint40;
          a {
            width: $linkWidth - $activeLinkIndecatorWidth;
            .productIcon {
              margin: 10px 0px 10px (10px - $activeLinkIndecatorWidth);
            }
          }
        }
      }

      @media (max-width: $ms-screen-min-xl - 1) {
        display: none;
      }
    }

    .tab-content {
      width: 100%;
    }
  }
}
