.filter-search-box {
  padding: 8px;
}
.filter-search-box-list {
  overflow-y: auto;
  margin-top: 8px;
}
.dropdown-item-header {
  font-weight: 600;
  margin-left: 10px;
  font-size: 12px;
  color: rgb(50, 49, 48);
}
.locations-label {
  margin-right: 16px;
}
.action-button {
  width: 100%;
}
