$contact-sent-dialog-height: 380px;

.contact-message-sent-dialog {
  direction: ltr;
  text-align: left;
  height: $contact-sent-dialog-height;
}
.contact-message-sent-dialog-rtl {
  direction: rtl;
  text-align: right;
  height: $contact-sent-dialog-height;
}
.contact-message-sent-titlebox {
  width: 560px;
  display: flex;
  margin-top: 16px;
  flex-direction: row;
}
.contact-message-sent-title {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  width: 100%;
}
.contact-message-sent-cancel-icon {
  margin-left: 24px;
  color: #323130;
  position: absolute;
  right: 0px;
  @media (min-width: $ms-screen-min-md) {
    right: 24px;
  }
}
.contact-message-sent-cancel-icon-rtl {
  color: #323130;
  position: absolute;
  left: 0px;
  @media (min-width: $ms-screen-min-md) {
    left: 24px;
  }
}

.contact-message-sent-main-box {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-message-sent-icon {
  width: 100px;
  height: 100px;
}
.contact-message-sent-message {
  font-size: 17px;
  font-weight: 400;
  margin-top: 20px;
  width: 280px;
  text-align: center;
}
.contact-message-sent-message-bold {
  font-weight: 600;
}
.contact-message-sent-buttons-box {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 66px;
}
