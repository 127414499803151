* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; /* 1 rem = 10px */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  width: 100%;
}

.app {
  direction: ltr;
  &.rtl {
    direction: rtl;
  }
}

.app-iframe {
  @extend .app;
  max-width: 1920px;
  margin: auto;
}

.lang-selector {
  width: '100%';
  padding-inline-start: 5rem;
  background-color: 'gray';
}
