.contact-partner-dialog {
  max-width: 672px;
  display: flex;
  flex-direction: column;
  @media (max-width: $ms-screen-min-lg) {
    overflow-y: none;
  }
  padding-top: 16px;
  .header-title {
    font-size: 20px;
    font-weight: 600;
    padding-left: 24px;
    padding-right: 24px;
  }
  .header-panel {
    display: flex;
    flex-direction: row;
  }
  .header-box {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 24px;
    .header-content-box {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
      margin-right: 12px;
    }
    .partner-name {
      font-size: 18px;
      font-weight: 600px;
    }
    .partner-logo {
      max-width: 44px;
      max-height: 44px;
    }
  }
  .location-drop-down {
    margin-top: 6px;
  }
  .focus-areas-drop-down {
    margin-top: 6px;
  }
  .disclaimer-seperator {
    margin-top: 8px;
  }
  .disclaimer-box {
    margin-top: 16px;
    margin-left: 24px;
    margin-right: 24px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
  }

  .disclaimer-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .content-box {
    padding-left: 24px;
    padding-right: 24px;
    flex: 1 1 auto;
    margin-top: 8px;
    @media (min-width: $ms-screen-min-lg) {
      overflow-y: auto;
    }
    .content-paragraph {
      margin-top: 6px;
      font-size: 14px;
      font-weight: 400;
      min-height: 40px;
    }
    .content-fields-row {
      display: flex;
      flex-direction: column;
      margin-top: 6px;
      @media (min-width: $ms-screen-min-md) {
        flex-direction: row;
      }
    }
    .content-fields-row-no-margin {
      display: flex;
      flex-direction: column;
      @media (min-width: $ms-screen-min-md) {
        flex-direction: row;
      }
    }
    .separator {
      margin-top: 14px;
    }
    .content-field {
      flex-basis: calc(50% - 6px);
      flex: 1, 1, 0px;
      @media (min-width: $ms-screen-min-md) {
        &:nth-of-type(2n) {
          margin-left: 12px;
        }
      }
    }
  }
  .buttons-box {
    text-align: right;
    margin-top: 16px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .contact-button-progress-box {
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: center;
    align-items: center;
  }
  .contact-spinner {
    margin-left: 8px;
    margin-right: 8px;
  }
}
