.filter-tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.filter-tooltip .tooltiptext {
  visibility: hidden;
  font-size: 12px;
  background-color: white;
  color: #323130;
  text-align: center;
  padding: 4px 0;
  border-radius: 2px;
  border-color: #323130;
  border-width: 1px;
  border-style: solid;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 10;
}

/* Show the tooltip text when you mouse over the tooltip container */
.filter-tooltip:hover .tooltiptext {
  visibility: visible;
}

.filter-container-box {
  align-items: stretch;
  overflow: hidden;
  background: #faf9f8;
  border-radius: 2px;
  padding: 16px;
  @media (max-width: $ms-screen-min-xl) {
    padding: 8px 16px;
  }
  @media (min-width: $ms-screen-min-xl) {
    max-width: 275px;
  }
}

.filters-expand-button {
  i,
  &:active i,
  &:hover i.ms-Button-icon {
    color: #000;
  }

  @media (min-width: $ms-screen-min-xl) {
    display: none;
  }
}

.filter-location-box {
  padding-left: 16px;
  padding-right: 16px;
}

.filter-location-label-box {
  display: flex;
  flex-direction: row;
}

.filter-location-icon-box {
  min-width: 16px;
  min-height: 16px;
}

.filter-location-label {
  font-size: 14px;
  font-weight: 400;
}

.filter-location-edit-box {
  margin-left: 12px;
  margin-top: 8px;
}

.filter-location-edit-link {
  font-size: 14px;
  font-weight: 400;
}

.filter-group-action-button {
  height: 30px;
  margin-left: 30px;
  font-weight: 400;
}

.filter-search-result-bold {
  font-weight: 700;
}

.filter-item-action-button {
  height: 30px;
}

.filter-check-l2-left-margin {
  margin-left: 16px;
}

.filter-check-l1-left-margin {
  margin-left: 4px;
}

.filter-check-text-left-margin {
  margin-left: 6px;
  overflow: hidden;
  white-space: nowrap;
}

.filters-title-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filters-title-text {
  text-align: start;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
}

.filters-title-clear-link {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 400;
  align-self: flex-end;
}

.filters-search-container {
  text-align: start;
  margin-top: 21px;
}

.filters-location-container {
  text-align: start;
  margin-top: 16px;
}

.filters-nav-container {
  text-align: start;
  margin-top: 0px;
}
