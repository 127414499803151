.error-boundary-page {
  display: flex;
  width: 100%;
  height: 80vh;
  align-items: 'center';
  justify-content: 'center';

  .error-boundary-container {
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    .error-boundary-title {
      font-weight: 600;
      font-size: 18px;
      margin-top: 28px;
      color: #323130;
    }
  }
}
