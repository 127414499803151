//colors
$linkColor: #006fc2;
$errorText: red;
$tintLightBackground: #faf9f8;
$darkColor: #605e5c;
$lightColor: #323130;
$darkBackground: #f1f1f1;
$lightBackground: #fff;
$darkBorderColor: rgb(0, 120, 212);
$search-text-color: #201f1e;
$search-color: #ffffff;
$search-border-color: #0078d4;
$search-dropdown-color: #edebe9;
$search-not-in-use-color: #edebe9;

//width and height
$mobileWidth: 450px;
$iconShadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
