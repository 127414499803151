$partner-icon-padding: 5px;
.card {
  flex-wrap: wrap;
  padding: 16px;
  margin: 5px;
  margin-left: 0px;
  background-color: #fff;
  display: flex;
  @media(max-width: 320px){
    padding: 8px;
  }
  .partner-icon-host {
    margin: 16px;
    padding: 8px;
    margin-right: 32px;
    width: 150px;
    height: 150px;
    position: relative;
    overflow: hidden;
    .partner-large-icon {
      img {
        position: absolute;
        left: $partner-icon-padding;
        top: $partner-icon-padding;
        display: block;
        width: calc(100% - #{$partner-icon-padding * 2});
        height: calc(100% - #{$partner-icon-padding * 2});
        -o-object-fit: scale-down;
        object-fit: scale-down;
      }
    }
  }
  .partner-location-box {
    margin-top: 6px;
  }
  .header {
    margin-top: 8px;
    margin-bottom: 16px;
    .title-block {
      .title {
        color: #333333;
      }
      .sub-title {
        margin-top: 8px;
        color: #605e5c;
        font-weight: normal;
      }
      .main-content {
        margin-top: 12px;
      }
    }
  }
}
