.loading-panel {
  height: 700px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.partner-details-wrapper {
  padding: 5rem;
  margin-top: 2rem;

  .logo {
    width: 20rem;
  }
}

.empty-partner-details {
  height: 1000px;
}

.contact-button-margin {
  margin-top: 12px;
}

$metadataWidth: 164px;
$hyperlinkColor: #006fc2;
$textColor: #1a1a1a;
$linkedItemsColor: #0078d4;
$whiteBackground: #ffffff;
$greyOutline: #8a8886;

@mixin text-overflow-style() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mobile-media {
  @media only screen and (max-device-width: 768px) and (max-device-height: 768px) and (-webkit-min-device-pixel-ratio: 2),
    (-webkit-min-device-pixel-ratio: 3) {
    display: none;
  }
}

.as-desktop-app-details {
  padding-bottom: 50px;
  align-self: center;
  min-height: 1000px;
  width: 100%;
  height: 100%;
  margin: auto;

  .spza_detail-container {
    height: 100%;

    .stickyCardPricing {
      align-self: center;
      margin-right: 8px;
      max-width: 220px;
      @media (max-width: 1023px) {
        display: none;
      }
    }

    .sticky-card-button {
      margin-bottom: 8px;

      .c-button {
        border-radius: 2px;
      }
    }

    .stickycard-favourite {
      @media (max-width: 1023px) {
        display: none;
      }
      margin-left: 10px;
      align-self: center;
    }

    .pdp-details {
      max-width: 1920px;
      margin: auto;
      padding: 0px 64px;
      width: 100%;
      @media (max-width: $ms-screen-min-lg) {
        padding: 0px 20px;
      }
      @media (min-width: $ms-screen-min-lg) and (max-width: $ms-screen-min-xl) {
        padding: 0px 28px;
      }
    }

    .navigation-bar {
      height: 60px;
      .breadcrumb {
        @include text-overflow-style();
        .go-back-button,
        .app-tab-button {
          background-color: transparent;
          margin: 0;
          font-size: 14px;
          line-height: 60px;
          max-width: none;
          min-width: initial;
          display: inline;
          border: none;
        }
        .go-back-button {
          padding: 0 10px 0 0;
          &:hover {
            color: $linkColor;
            cursor: pointer;
            text-decoration: underline;
          }
        }
        .app-tab-button {
          padding: 0 6px;
          cursor: default;
        }
        span {
          line-height: 57px;
          font-size: 11px;
          &:focus {
            border: 1px dotted $linkColor;
            outline: none;
          }
          &:before {
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    .tab-container {
      clear: both;
      .separator {
        border-bottom: 1px solid #d3d3d3;
        position: relative;
        top: -8px;
      }
      .default-tab {
        float: left;
        outline: none;
        height: 44px;

        &:focus {
          & > label {
            border-bottom: 2px solid #0078d4;
          }
        }

        > label {
          display: inline-block;
          margin: 0 10px -2px 0;
          padding: 12px 8px 12px 8px;
          text-align: center;
          color: #323130;
          cursor: pointer;
          font-size: 14px;
          line-height: 20px;
          &:hover {
            background-color: #f3f2f1;
          }
        }
        [type='radio'] {
          display: none;
        }
      }
      .tab-selected {
        height: 44px;

        > label {
          color: #323130;
          font-weight: 600;
          border-bottom: 2px solid #0078d4;
          &:hover {
            background-color: #f3f2f1;
          }
        }
      }
      > .tab-content {
        display: block;
        position: relative;
        top: 10px;
        clear: both;
        padding-left: 5px;
      }
    }
    .detail-content {
      .diverse-container {
        width: fit-content;
      }
      .diverse-block {
        display: flex;
        flex-direction: row;
        background: #deecf9;
        border-radius: 2px;
        align-items: center;
        width: fit-content;
        padding-left: 8px;
        padding-right: 8px;
        margin-top: 15px;
        .diverse-label {
          margin-left: 8px;
          font-weight: 400;
          font-size: 13px;
        }
      }
      .title-block {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        flex-grow: 1;
        .partner-location-box {
          display: flex;
          .partner-location-box-content {
            flex-basis: 500px;
          }
        }
        .container {
          align-items: center;
          margin-top: 4px;
          display: flex;
          flex-wrap: wrap;
          .appSource-spza-user-favourite-tile-detail-button {
            height: 32px;
          }
        }
        .product-hyperlink {
          text-decoration: none;
          color: #006fc2;
        }
        .contact-button {
          margin-right: 8px;
          border-radius: 2px;
          font-size: 14px;
          height: 32px;
        }
        .badges-block {
          @media (max-width: 5) {
            position: static;
            margin: 10px 0px;
            .banner {
              margin-top: 10px;
            }
          }
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          flex-wrap: wrap;
          margin: 32px;
          .banner {
            margin-right: 5px;
            height: 32px;
          }
        }
      }
      .pdp-tabs {
        margin: 32px 32px 0 32px;
        @media (max-width: $ms-screen-min-lg) {
          margin: 20px 20px 0 20px;
        }
      }
      .metadata {
        width: $metadataWidth;
        height: 100%;
        flex: none;
        margin-right: 30px;
        text-align: center;
        .cell {
          margin-bottom: 12px;
          text-align: left;
          header,
          h2 {
            color: $textColor;
            font-weight: 600;
            font-size: 12px;
            margin-top: 8px;

            img {
              height: 15px;
              width: 15px;
              padding-left: 2px;
            }
          }
          span {
            font-size: 12px;
          }
          .tooltip {
            display: inline;
            position: relative;
          }
          .tooltip:hover:after {
            background: #333;
            background: white;
            border-radius: 3px;
            border: 1px;
            bottom: 26px;
            color: black;
            content: attr(alt);
            top: 20%;
            padding: 2px 2px 2px 2px;
            position: absolute;
            z-index: 98;
            max-width: 1000;
          }
        }
        .c-hyperlink {
          text-align: left;
          font-size: 12px;
          display: block;
        }
        .metaDetails {
          margin-top: 8px;
          padding: 0 0 0 1px;
          font-size: 12px;
          .cell {
            margin-bottom: 12px;
            text-align: left;
            h2 {
              color: $textColor;
              margin-bottom: 2px;
              font-weight: 600;
              font-size: 12px;
            }
            .thunk {
              background: #f2f2f2;
              .miniIcon {
                margin-right: 6px;
                height: 30px;
                width: 30px;
                float: left;
              }
              .forApp {
                line-height: 30px;
                padding-right: 16px;
                @include text-overflow-style();
              }
            }
            .details-rating {
              .c-glyph {
                width: 18px;
                height: 18px;
                margin-right: 2px;
              }
              .c-glyph:after {
                color: $linkColor;
                font-size: 18px;
              }
              .c-glyph:before {
                font-size: 18px;
              }
            }
            .ratings-count {
              font-size: 16px;
              line-height: 20px;
            }
            .details-categories {
              color: $linkColor;
              cursor: pointer;
              &.d365FilterGroupHeader {
                display: none;
              }
              &:hover {
                text-decoration: underline;
              }
            }
            > span,
            > a {
              display: block;
            }
            .region-dialog {
              border: 1px solid #e6e6e6;
              width: 500px;
              left: 50px;
              background: white;
              cursor: default;
              z-index: 1000;
              overflow-y: hidden;
              overflow-x: hidden;
              max-height: 707px; /* approximate max height */
              transition-property: all;
              transition-duration: 0.5s;
              transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
              position: relative;

              .overlay {
                position: fixed;
                background: white;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 999;
              }

              .modalContent {
                position: relative;
                display: flex;
                flex-direction: column;
                color: #000;

                .regions-header {
                  margin-left: 15px;
                  font-size: 15px;
                  font-weight: 200;
                  margin-bottom: 15px;
                }

                .cancel {
                  border: none;
                  background: white;
                  float: right;
                  padding: 0 6px;

                  span {
                    &:after {
                      width: 10px;
                      height: 10px;
                      line-height: 12px;
                      margin-right: 10px;
                      margin-top: 5px;
                    }
                  }
                }
              }
            }

            .dialog-closed {
              max-height: 0;
              border: none;
            }

            .region-link {
              color: $linkColor;
              &:hover {
                cursor: pointer;
              }
            }
          }
          .products-supported {
            span {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
      }
      .deatil-page-content {
        flex: 1;
        position: relative;
        .spza-group {
          .header-wrap {
            margin-top: 0;
            > h3 {
              padding: 0;
            }
            .spza-header-text {
              padding: 25px 0px 26px;
            }
          }
          .spza-pagerWrapper {
            margin: 0 0 0 -20px;

            .spza-pager {
              height: 100%;
            }
          }
        }
        // Overview tab
        .overview {
          min-height: 200px;
          padding-bottom: 20px;

          .title-explanation {
            font-size: 12px;
            font-weight: 400;
            margin-top: 4px;
          }

          .partner-description {
            margin-top: 16px;
            font-size: 14px;
            white-space: pre-wrap;
            a {
              text-decoration: none;
              color: $hyperlinkColor;
            }

            a:visited,
            a:hover {
              color: $hyperlinkColor;
              text-decoration: underline;
            }
          }
          .description {
            margin-top: 4px;
            font-size: 14px;
            a {
              text-decoration: none;
              color: $hyperlinkColor;
            }

            a:visited,
            a:hover {
              color: $hyperlinkColor;
              text-decoration: underline;
            }
          }
          .description-ul {
            margin-left: 16px;
            list-style-position: 'outside';
            list-style-type: initial;
          }
          .description-li {
            margin-top: 4px;
            font-size: 14px;
            max-width: 230px;
          }
          .description-li-wide {
            margin-top: 4px;
            font-size: 14px;
            max-width: 480px;
          }
          .legal-warning {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            margin-top: 8px;
          }
          .competency-sub-title {
            font-weight: 600;
            margin-top: 22px;
            font-size: 14px;
          }
          .title-section {
            display: inline-flex;
            align-items: center;
            margin-top: 46px;
            .title-icon {
              line-height: 20px;
              vertical-align: center;
            }
            .title-text {
              font-size: 16px;
              font-weight: 600;
              line-height: 22px;
              text-overflow: ellipsis;
              overflow: hidden;
              margin-left: 8px;
              margin-right: 8px;
            }
            .title-text-no-icon {
              font-size: 16px;
              font-weight: 600;
              line-height: 22px;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            .learn-more-link {
              font-size: 14px;
              font-weight: 400;
              margin-left: 8px;
              color: #0078d4;
            }
            .title-link {
              margin-left: 5px;
              margin-top: 0px;
              font-weight: 600;
              text-align: left;
              font-size: 14px;
              display: block;
            }
          }
          .details-content-wrapper {
            display: flex;
            flex-direction: column;
          }
          .appdetail-content {
            padding-bottom: 15px;
            flex: 1;
            -ms-flex: 1;
            max-width: 1000px;
            @media (max-width: 1200px) {
              -ms-flex: none;
            }
            &.noMedia {
              max-width: 800px;
            }
            .link {
              margin-top: 20px;
              .linkTitle {
                padding: 0;
              }
              .linkContent {
                font-size: 13px;
                display: block;
              }
            }
            .details-content-header {
              font-size: 16px;
              font-weight: 600;
              line-height: 22px;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          .overview-box {
            display: flex;
            flex-direction: column;
          }
          .four-columns-box {
            display: flex;
            flex-wrap: wrap;
          }
          .four-columns-box .col {
            min-width: 250px;
          }
          .four-columns-box-li {
            max-width: 250px;
            font-size: 14px;
            font-weight: 400;
          }
          .two-columns-box {
            display: flex;
            flex-wrap: wrap;
          }
          .two-columns-box .col {
            min-width: 500px;
          }
          .two-columns-box-li {
            max-width: 500px;
            font-size: 14px;
            font-weight: 400;
          }
          .adv-spec-box {
            margin-top: 12px;
          }
          .diverse-business-box {
            margin-top: 12px;
          }
          .additional-info-box {
            display: flex;
            flex-direction: column;
          }
          .website-link {
            margin-inline-start: 8px;
            margin-right: 8px;
            margin-top: 16px;
            line-height: 20px;
            text-align: inherit;
            font-size: 14px;
            display: block;
          }
        }
      }
      .header {
        margin-left: 0;
        overflow: hidden;
        display: flex;
        flex-grow: 1;

        @media (max-width: $ms-screen-min-sm) {
          width: 100%;
        }
      }
    }
    .embed-breadcrumb-button {
      &:hover {
        cursor: pointer;
        color: $linkColor;
      }
      span {
        &:after {
          font-size: 12px;
          margin-right: 6px;
        }
      }
    }
  }
}
$buttonColor: #006fc2;

.breadcrumb {
  line-height: 57px;
  font-size: 11px;
  .breadcrumbItem {
    display: inline-block;
  }
  @include text-overflow-style();
  .goBackButton,
  .appTabButton {
    background-color: transparent;
    margin: 0;
    font-size: 14px;
    display: inline;
    border: none;
  }
  .goBackButton {
    padding: 0 10px 0 0;
    font-size: 14px;
    &:hover {
      color: $buttonColor;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .appTabButton {
    padding: 0 6px;
    cursor: default;
  }
}
