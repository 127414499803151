body {
  color: #323130;
}

h1 {
  color: #323130;
}

h2 {
  color: #323130;
}

h3 {
  color: #323130;
}

div {
  color: #323130;
}

p {
  color: #323130;
}

li {
  color: #323130;
}
