.tile-container {
  cursor: pointer;
  width: 220px;
  height: 370px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  text-align: left;
  overflow: hidden;

  &:hover {
    background: #faf9f8;
  }

  .tile-top-section {
    display: flex;
    justify-content: space-between;
  }

  .tile-icon {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.5px;
    background: white;
  }

  .name {
    font-size: 18px;
    font-weight: 600;
    height: 48px;
    margin-top: 12px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .location {
    font-size: 12px;
    font-weight: 400;
    margin-top: 8px;
    text-align: left;
    color: #605e5c;
    width: 100%;
  }

  .card-products-box {
    width: 100%;
    margin-top: 8px;

    .product-item {
      font-size: 12px;
      font-weight: 400;
      margin-right: 10px;
      color: #605e5c;
    }
  }

  .tile-bottom-box {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    flex-direction: column;
    max-width: 100%;
    overflow: hidden;
  }

  .description {
    font-size: 12px;
    font-weight: 400;
    margin-top: 12px;
    text-align: left;
    color: #605e5c;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }

  .contact-section-wrapper {
    margin-top: 20px;

    .contact-button {
      margin-right: 8px;
      border-radius: 2px;
      font-size: 14px;
      font-weight: 600;
      height: 32px;
    }
  }
}
