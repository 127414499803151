$gallery-banner-effect-transparency: 0.95;

.gallery-container {
  width: 100%;
  height: 100%;
  min-height: 1000px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  .gallery-header-tile-filters {
    margin: 0 0 12px 0px;
    display: flex;
    width: 200;
    flex-flow: row wrap;

    .tile-filter {
      @media (max-width: 539px),
        screen and (max-device-width: 768px) and (max-device-height: 768px) and (-webkit-min-device-pixel-ratio: 2),
        (-webkit-min-device-pixel-ratio: 3) {
        display: none;
      }
    }
  }
  .loading-panel {
    max-height: 100px;
    max-width: 100px;
    display: flex;
    justify-content: left;
    align-items: flex-start;
  }
  .tiles-container {
    display: grid;
    //grid-template-columns: repeat(5, 1fr);
    grid-template-columns: repeat(auto-fill, 240px);
    grid-gap: 6px;
    text-align: center;
    margin-top: 14px;
  }
  .results-container {
    display: flex;
    flex-direction: column;
  }
  .results-title {
    font-weight: 400;
    font-size: 20px;
    margin-top: 24px;
    align-self: flex-start;
  }
  .loading-panel {
    height: 100rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .empty-gallery-container {
    width: 100%;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .empty-gallery-title {
    font-weight: 600;
    font-size: 18px;
    margin-top: 28px;
  }
  .empty-gallery-text {
    font-weight: 400;
    font-size: 12px;
    margin-top: 20px;
  }
  .empty-gallery-button {
    margin-top: 28px;
  }
  .results-pagination-panel {
    margin-top: 72px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .zero-border {
    border: 0px;
  }
  .page-index-button {
    width: 40px;
  }

  .gallery-header-sort-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    padding-right: 48px;
    @media (max-width: $ms-screen-min-xl) {
      .gallery-header-sortby-label-rtl,
      .gallery-header-sortby-label {
        display: none;
      }
    }
  }

  .gallery-header-sortby-label {
    margin-left: 8px;
    font-weight: 400;
  }

  .gallery-header-sortby-label-rtl {
    margin-right: 8px;
    font-weight: 400;
  }

  .gallery-banner-wrapper {
    height: 200px;
    display: flex;
    position: relative;
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    background-image: url('./../../../images/background/gallery_banner.svg');
    overflow: hidden;

    @media (max-width: $ms-screen-min-xl) {
      display: none;
    }

    .gallery-banner-content {
      padding: 24px 20px;
      background: rgba(255, 255, 255, $gallery-banner-effect-transparency);
      height: 100%;
      position: relative;

      @media (max-width: $ms-screen-min-xxl) {
        width: 100%;
      }

      @media (min-width: $ms-screen-min-xxl) {
        max-width: 50%;
        &::after {
          content: '';
          position: absolute;
          height: 100%;
          bottom: 0;
          width: 100px;
          left: 100%;
          background: url(../../../images/background/background-rectangle-mask.svg) no-repeat bottom right;
          opacity: $gallery-banner-effect-transparency;
        }
      }

      .gallery-banner-title {
        font-size: 28px;
        color: $ms-color-communicationShade10;
        margin-bottom: 16px;
        font-weight: 600;
      }

      .gallery-banner-description {
        line-height: 20px;
        font-size: 14px;
        font-weight: 600;
        color: $ms-color-gray160;
        max-width: 400px;
      }
    }
  }
}
